import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { updatePlantType } from '../../services/CustomerService';
import { PlantType } from '../../utils/app.enums';

export default function UserGoal({ propertyId }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const options = [
    {
      id: 1,
      name: 'Reduce electricity bills',
      value: PlantType.ON_GRID,
      details: 'Ongrid - subsidy applicable',
    },
    {
      id: 2,
      name: 'Relief from power cuts',
      value: PlantType.OFF_GRID,
      details: 'Offgrid - no subsidy, battery costs extra',
    },
    {
      id: 3,
      name: 'Both the above',
      value: PlantType.UTILITY,
      details: '',
    },
    {
      id: 4,
      name: 'I do not know yet',
      value: PlantType.OTHER,
      details: 'Our experts will help choose the right plant',
    },
  ];

  const handleCardClick = (index) => {
    setSelectedOption(selectedOption === index ? null : index);
  };

  const handleConfirm = async () => {
    if (selectedOption !== null) {
      const selectedOptionDetails = options[selectedOption];
      await updatePlantType(propertyId, selectedOptionDetails.value);
      window.location.reload();
    }
  };

  return (
    <Stack
      alignItems={isMobile ? 'flex-start' : 'center'}
      padding={isMobile ? '0px 16px' : 'unset'}
    >
      <Typography
        sx={{
          fontSize: {
            xs: theme.typography.h4.fontSize,
            md: theme.typography.h3.fontSize,
          },
          fontWeight: 'bold',
          // marginTop: '10px',
        }}
      >
        Your goal
      </Typography>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'} width={'100%'}>
        {options.map((option, index) => (
          <Card
            key={option.id}
            sx={{
              backgroundColor:
                selectedOption === index
                  ? theme.palette.secondary.lighter
                  : theme.palette.background.paper,
              cursor: 'pointer',
              width: '100%',
              marginTop: 2,
              borderRadius: '10px',
              boxShadow: '2px 2px 2px 2px #D3D3D3',
              maxWidth: '500px',
            }}
            onClick={() => handleCardClick(index)}
          >
            <CardContent>
              <Typography sx={{ fontWeight: 'bold', paddingBottom: '4px' }}>
                <Typography
                  component='span'
                  sx={{ color: 'green' }}
                  fontWeight='bold'
                >
                  {option.name}
                </Typography>
              </Typography>
              <Typography variant='caption'>{option.details}</Typography>
              {selectedOption === index && (
                <>
                  <Grid container mt={2} justifyContent={'flex-end'}>
                    <Button variant='contained' onClick={handleConfirm}>
                      Confirm
                    </Button>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
}
