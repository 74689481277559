import ReactGA4 from 'react-ga4';

export const isDevEnv = process.env.REACT_APP_ENVIRONMENT === 'development';

export const toSentenceCase = (sentence) => {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
};

export const isLoggedIn = () => {
  return !!localStorage.getItem('accessToken');
};

export const trackGoogleAnalyticsEvent = (category, event_name, data) => {
  let event_params = {
    category,
    ...data,
  };
  // Send GA4 Event
  if (!isDevEnv) {
    ReactGA4.event(event_name, event_params);
  }
};

export const calculateYears = (establishmentDate) => {
  const currentDate = new Date();
  const establishmentDay = new Date(establishmentDate);
  const years = currentDate.getFullYear() - establishmentDay.getFullYear();
  const monthDifference = currentDate.getMonth() - establishmentDay.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 &&
      currentDate.getDate() < establishmentDay.getDate())
  ) {
    return years - 1;
  }

  return years;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-IN', options);
};

export const truncateFileName = (fileName, length = 20) => {
  if (fileName.length <= length) return fileName;
  return `${fileName.substr(0, length)}...`;
};

export const truncateText = (text, maxLength = 20) => {
  if (text.length <= maxLength) return text;
  return `${text.substr(0, maxLength)}...`;
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'N/A';
  const countryCode = '+91';
  const formattedNumber = phoneNumber.replace(
    /^(\d{2})(\d{5})(\d{5})$/,
    `${countryCode} $2 $3`,
  );
  return formattedNumber;
};
