import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import Logo from './Logo';

const Footer = () => {
  const handleSupportButtonClick = () => {
    window.open('https://forms.gle/FYBfYapmfg77pZkz9', '_blank');
  };
  return (
    <Box bgcolor='#EEF2EA'>
      <Box maxWidth='1450px' mx='auto'>
        <Stack
          px={{ xl: '130px', lg: '100px', md: '20px', xs: '24px' }}
          pt='83px'
          pb={{ xl: '201px', md: '140px', xs: '100px' }}
          rowGap={10}
        >
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            rowGap={5}
            justifyContent={'space-between'}
            columnGap={6}
          >
            <Stack rowGap={1.5} justifyContent={'flex-start'}>
              <Logo placement='start' hght='54px' />
              <Stack rowGap={'6px'} pl={{ md: '15px', xs: '0px' }}>
                <Typography fontWeight='bold' variant='body1'>
                  Easiest way to go{' '}
                  <Typography
                    fontWeight='bold'
                    variant='body1'
                    color='primary.main'
                    component={'span'}
                  >
                    solar!
                  </Typography>
                </Typography>

                <Typography variant='body2'>
                  Copyright © 2024 VaySolar. Made in India for the world.
                </Typography>
                <Stack direction='row' columnGap={1} pb={0.5}>
                  <Link
                    target='_blank'
                    color='inherit'
                    underline='none'
                    variant='body2'
                    href='https://www.facebook.com/vaysolar'
                  >
                    Facebook
                  </Link>
                  {' | '}
                  <Link
                    target='_blank'
                    color='inherit'
                    underline='none'
                    variant='body2'
                    href='https://www.linkedin.com/company/vaysolar/'
                  >
                    LinkedIn
                  </Link>
                  {' | '}
                  <Link
                    target='_blank'
                    color='inherit'
                    underline='none'
                    variant='body2'
                    href='https://www.vaysolar.com/docs/blog/'
                  >
                    Blog
                  </Link>
                </Stack>
                <Link
                  target='_blank'
                  color='inherit'
                  underline='none'
                  variant='body2'
                  onClick={handleSupportButtonClick}
                >
                  Help Center
                </Link>
              </Stack>
            </Stack>
            <Stack rowGap={'30px'} justifyContent={'flex-start'}>
              <Link
                target='_blank'
                href='https://www.vaysolar.com/docs'
                color='inherit'
                underline='none'
                fontWeight='bold'
                variant='h5'
              >
                Solar Research
              </Link>
              <Stack rowGap={'14px'}>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://www.vaysolar.com/docs/research/how-it-works/'
                >
                  <Typography variant='body2' color='inherit' target='_blank'>
                    How solar works?
                  </Typography>
                </Link>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://www.vaysolar.com/docs/research/benefits/'
                >
                  <Typography variant='body2' color='inherit' target='_blank'>
                    Benefits of solar
                  </Typography>
                </Link>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://www.vaysolar.com/docs/research/cost/'
                >
                  <Typography variant='body2' color='inherit' target='_blank'>
                    Cost of solar
                  </Typography>
                </Link>
              </Stack>
            </Stack>
            <Stack rowGap={'30px'} justifyContent={'flex-start'}>
              <Link
                target='_blank'
                color='inherit'
                underline='none'
                fontWeight='bold'
                variant='h5'
                href='https://www.vaysolar.com/docs/buying-guide/'
              >
                Solar Buying Guide
              </Link>
              <Stack rowGap={'14px'}>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://www.vaysolar.com/docs/buying-guide/is-solar-right/'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://www.vaysolar.com/docs/buying-guide/is-solar-right/'
                    target='_blank'
                  >
                    Is solar right for you?
                  </Typography>
                </Link>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://www.vaysolar.com/docs/buying-guide/get-quotes/'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://www.vaysolar.com/docs/buying-guide/get-quotes/'
                    target='_blank'
                  >
                    Get quotes & compare quotes
                  </Typography>
                </Link>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://www.vaysolar.com/docs/buying-guide/choose-installer/'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://www.vaysolar.com/docs/buying-guide/choose-installer/'
                    target='_blank'
                  >
                    Choosing an installer
                  </Typography>
                </Link>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://www.vaysolar.com/docs/buying-guide/pitfalls/'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://www.vaysolar.com/docs/buying-guide/pitfalls/'
                    target='_blank'
                  >
                    Common pitfalls
                  </Typography>
                </Link>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            rowGap={5}
            columnGap={16}
            pl={{ md: '15px', xs: '0px' }}
          >
            <Stack rowGap={'20px'}>
              <Link
                target='_blank'
                color='inherit'
                underline='none'
                fontWeight='bold'
                variant='h5'
                href='https://docs.google.com/forms/d/e/1FAIpQLSeWfCZ1zbPaS0IdNMwX8cc5jji-XGR-b3OUcl3Rg4pWbEuWxA/viewform'
                cursor='pointer'
              >
                Partner with us.
              </Link>
              <Stack rowGap={'14px'}>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://forms.gle/2DtM25ahtYZnw9g56'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://forms.gle/2DtM25ahtYZnw9g56'
                    target='_blank'
                  >
                    For installers
                  </Typography>
                </Link>
                <Link
                  color='inherit'
                  underline='none'
                  target='_blank'
                  href='https://forms.gle/2DtM25ahtYZnw9g56'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://forms.gle/2DtM25ahtYZnw9g56'
                    target='_blank'
                  >
                    For solar consultants
                  </Typography>
                </Link>
              </Stack>
            </Stack>
            <Stack rowGap={'20px'}>
              <Link
                target='_blank'
                color='inherit'
                underline='none'
                fontWeight='bold'
                variant='h5'
                href='https://docs.google.com/forms/d/e/1FAIpQLSdW1e8ZevF02i4FOYcakmaLBVDVGAFxWtu2h4Z_ll-VybU5-w/viewform'
              >
                Contributions.
              </Link>
              <Stack rowGap={'14px'}>
                <Link
                  target='_blank'
                  color='inherit'
                  underline='none'
                  href='https://forms.gle/9FSpLfeW5eBw2ot39'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://forms.gle/9FSpLfeW5eBw2ot39'
                    target='_blank'
                  >
                    Technology contributions
                  </Typography>
                </Link>
                <Link
                  target='_blank'
                  color='inherit'
                  underline='none'
                  href='https://forms.gle/9FSpLfeW5eBw2ot39'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://forms.gle/9FSpLfeW5eBw2ot39'
                    target='_blank'
                  >
                    Beta user program
                  </Typography>
                </Link>
                <Link
                  target='_blank'
                  color='inherit'
                  underline='none'
                  href='https://forms.gle/9FSpLfeW5eBw2ot39'
                >
                  <Typography
                    variant='body2'
                    color='inherit'
                    href='https://forms.gle/9FSpLfeW5eBw2ot39'
                    target='_blank'
                  >
                    Sustainable lifestyle
                  </Typography>
                </Link>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              justifyContent={'flex-end'}
              alignItems={{ md: 'flex-end' }}
            >
              <Stack direction='row' columnGap={1}>
                <Link
                  target='_blank'
                  color='inherit'
                  underline='none'
                  variant='body2'
                  href='https://www.vaysolar.com/docs/sitemap.xml'
                >
                  Sitemap
                </Link>
                {' | '}
                {/* <Link target="_blank" color="inherit" underline='none' variant="body2" >Disclaimer</Link>
                                {" | "} */}
                <Link
                  target='_blank'
                  color='inherit'
                  underline='none'
                  variant='body2'
                  href='https://www.vaysolar.com/docs/privacy/'
                >
                  Privacy Policy
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
