import { Edit, Security } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import DashboardStepper from '../components/dashboard/DashboardStepper';
import PlantSize from '../components/dashboard/PlantSize';
import SolarSearch from '../components/dashboard/SolarSearch';
import UserGoal from '../components/dashboard/UserGoal';
import PropertyDetails from '../components/ui/PropertyDetails';
import { DashboardLayout } from '../layout/DashboardLayout';
import {
  getProposalsByUserId,
  getRecommendations,
  getUserProperties,
} from '../services/CustomerService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { useSearchAnimate } from '../store/SearchAnimateStore';
import { InstallationTimeline } from '../utils/app.enums';

const DashboardPage = () => {
  const { updateTitle, updateDescription } = usePageTitleStore();
  const [vendorData, setVendorData] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [proposals, setProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [installationTimeline, setInstallationTimeline] = useState('1 month');
  const { setIsSearchOver, setIsLooking } = useSearchAnimate();
  const [recommendations, setRecommendations] = useState([]);
  const [showPlantTypeSelection, setShowPlantTypeSelection] = useState(true);
  const [showPlantSizeSelection, setShowPlantSizeSelection] = useState(true);
  const [lookingForSolar, setLookingForSolar] = useState(true);

  useEffect(() => {
    updateTitle('Dashboard | VaySolar');
    updateDescription(
      'VaySolar a platform for vendor and customer to unite world with solar power energy',
    );
  }, [updateDescription, updateTitle]);

  useEffect(() => {
    const onMount = async () => {
      try {
        const data = await getUserProperties();
        setPropertyDetails(data[0]);
        setInstallationTimeline(data[0]?.installationTimeline);
        if (
          data[0]?.installationTimeline === InstallationTimeline.NOT_LOOKING
        ) {
          setIsSearchOver(false);
          setIsLooking(true);
          setLookingForSolar(false);
        }
        if (
          data[0]?.plantType &&
          data[0]?.plantSize &&
          data[0]?.installationTimeline !== InstallationTimeline.NOT_LOOKING
        ) {
          setShowPlantTypeSelection(false);
          setShowPlantSizeSelection(false);

          const proposalsResponse = await getProposalsByUserId();
          setProposals(proposalsResponse);
          const recommendationsResponse = await getRecommendations();
          const recommendations = recommendationsResponse.filter(
            (recommendation) => recommendation.proposalConfig,
          );
          const vendorList = recommendationsResponse
            .filter((recommendation) => !recommendation.proposalConfig)
            .map((recommendation) => {
              return {
                ...recommendation?.vendor,
              };
            });
          setVendorData(vendorList);
          setRecommendations(recommendations);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    onMount();
  }, []);

  if (isLoading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
        sx={{
          flexGrow: 1,
          px: { sm: 3, xs: 1.5 },
          py: { sm: 3, xs: 2 },
          pb: { sm: 3, xs: 8 },
        }}
      >
        <CircularProgress color='primary' />
      </Box>
    );
  }

  const DashboardMainContent = () => {
    return (
      <>
        {propertyDetails?.plantSize && propertyDetails?.plantType && (
          <>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              my={2}
              ml={1}
              maxWidth={'300px'}
            >
              <Typography variant='h6' fontWeight={'bold'}>
                {propertyDetails.plantSize}kW{' '}
                {propertyDetails.plantType.toLowerCase()} solar
              </Typography>

              <Edit />
            </Stack>
            <Stack
              direction={'row'}
              my={2}
              ml={1}
              alignItems={'center'}
              gap={1}
            >
              <Security
                sx={{
                  color: 'green',
                  fontSize: '36px',
                  marginRight: '5px',
                }}
              />
              <Typography variant='h6'>5 year service warranty</Typography>
            </Stack>
          </>
        )}
        <DashboardStepper
          proposals={proposals}
          propertyDetails={propertyDetails}
          vendorData={vendorData}
          recommendations={recommendations}
        />
      </>
    );
  };

  return (
    <DashboardLayout
      announcementBar={true}
      propertyDetails={propertyDetails}
      installationTimeline={installationTimeline}
    >
      <Container
        component='main'
        sx={{
          flexGrow: 1,
          pb: 2,
          paddingInline: { md: '24px', xs: '12px' },
          paddingTop: '13px',
        }}
      >
        <Breadcrumbs
          aria-label='breadcrumb'
          sx={{
            pb: 2,
          }}
        >
          <Link underline='hover' variant='subtitle2' color='inherit' href='#'>
            Account
          </Link>
          <Link underline='hover' variant='subtitle2' href='#' color={'black'}>
            {propertyDetails?.sanctionedLoad} kW load
            {propertyDetails?.siteVisitData?.roofTopArea &&
              `, ${propertyDetails.siteVisitData.roofTopArea} sq ft`}
            {propertyDetails?.siteVisitData?.region &&
              ` @ ${propertyDetails.siteVisitData.region}`}
          </Link>
        </Breadcrumbs>
        <PropertyDetails
          textVariant='primary'
          overrideAlignment='start'
          propertyDetails={propertyDetails}
        />
        {!lookingForSolar ? (
          <SolarSearch propertyDetails={propertyDetails} />
        ) : showPlantSizeSelection ? (
          <PlantSize propertyDetails={propertyDetails} />
        ) : showPlantTypeSelection ? (
          <UserGoal propertyId={propertyDetails?.id} />
        ) : (
          DashboardMainContent()
        )}
      </Container>
    </DashboardLayout>
  );
};
export default DashboardPage;
