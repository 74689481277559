import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  getPlantSizeOptions,
  updatePlantSize,
} from '../../services/CustomerService';

const ThickSlider = styled(Slider)(({ isMobile }) => ({
  color: 'primary.main',
  height: 10,
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-rail': {
    height: 10,
    backgroundColor: 'red',
  },
  '& .MuiSlider-track': {
    height: 10,
  },
  '&::before': {
    content: '"Price range"',
    position: 'absolute',
    top: isMobile ? -2 : -6,
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'green',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  '& .MuiSlider-markLabel': {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

export default function PlantSize({ propertyDetails }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const createOptions = (plantOption) => {
    const optionsList = [];
    if (plantOption.fullEnergyNeedsPlantSize) {
      const plantData = plantOption.fullEnergyNeedsPlantSize.plantData;
      const option = {
        plantSize: plantData.plantSize,
        title: '100% of energy needs',
        description: 'reduce bill by',
        billReduction: plantData.billReduction,
        firstYearSavings: plantData.firstYearSavings,
        energyConsumption: plantData.energyConsumption,
        energyProduction: plantData.energyProduction,
        minPrice: plantData.installationCostRange.split('-')[0],
        maxPrice: plantData.installationCostRange.split('-')[1],
      };
      optionsList.push(option);
    }
    if (plantOption.loadPlantSize) {
      const plantData = plantOption.loadPlantSize.plantData;
      const option = {
        plantSize: plantData.plantSize,
        description: 'match existing load',
        billReduction: plantData.billReduction,
        firstYearSavings: plantData.firstYearSavings,
        energyConsumption: plantData.energyConsumption,
        energyProduction: plantData.energyProduction,
        minPrice: plantData.installationCostRange.split('-')[0],
        maxPrice: plantData.installationCostRange.split('-')[1],
        title: `${((plantData.energyProduction / plantData.energyConsumption) * 100).toFixed(2)}% of energy needs`,
      };
      optionsList.push(option);
    }
    //TODO: We will need this later, not now so commenting it out
    if (plantOption.roofAreaPlantSize) {
      const plantData = plantOption.roofAreaPlantSize.plantData;
      const option = {
        plantSize: plantData.plantSize,
        description: 'reduce bill by',
        billReduction: plantData.billReduction,
        firstYearSavings: plantData.firstYearSavings,
        energyConsumption: plantData.energyConsumption,
        energyProduction: plantData.energyProduction,
        minPrice: plantData.installationCostRange.split('-')[0],
        maxPrice: plantData.installationCostRange.split('-')[1],
        title: `${((plantData.energyProduction / plantData.energyConsumption) * 100).toFixed(2)}% of energy needs`,
      };
      optionsList.push(option);
    }
    return optionsList;
  };

  useEffect(() => {
    const onMount = async () => {
      try {
        const region = propertyDetails.address?.state || 'Delhi';
        const propertyType = propertyDetails.propertyType || 'RESIDENTIAL';
        const billAmount = propertyDetails.billAmount || 2000;
        const billMonth = propertyDetails.billMonth || 'JANUARY';
        const sanctionedLoad = propertyDetails.sanctionedLoad || 3;
        const shadowFreeArea = propertyDetails.shadowFreeArea || 500;
        const data = await getPlantSizeOptions(
          region,
          propertyType,
          billAmount,
          billMonth,
          sanctionedLoad,
          shadowFreeArea,
          0,
        );
        if (data.length === 0) throw new Error('No data found');
        setOptions(createOptions(data.plantOptions));
      } catch (error) {
        toast.error(error);
      }
    };
    onMount();
  }, []);

  const handleCardClick = (index) => {
    setSelectedOption(selectedOption === index ? null : index);
    console.log(selectedOption);
  };

  const handleConfirm = async () => {
    if (selectedOption !== null) {
      const selectedOptionDetails = options[selectedOption];
      const plantSize = selectedOptionDetails?.plantSize
        ?.split('kWp')[0]
        .trim();
      await updatePlantSize(propertyDetails?.id, plantSize);
      window.location.reload();
    }
  };

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.h6.fontSize,
            },
            fontWeight: 'bold',
          }}
        >
          For offers near you, select
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
              md: theme.typography.h4.fontSize,
            },
            fontWeight: 'bold',
          }}
        >
          Preferred plant size
        </Typography>
        <Stack alignItems={isMobile ? 'flex-start' : 'center'} width={'100%'}>
          {options.map((option, index) => (
            <Card
              key={index}
              sx={{
                backgroundColor:
                  selectedOption === index
                    ? theme.palette.secondary.lighter
                    : theme.palette.background.paper,
                cursor: 'pointer',
                width: '100%',
                marginTop: 2,
                borderRadius: '10px',
                boxShadow: '2px 2px 2px 2px #D3D3D3',
                maxWidth: '500px',
              }}
              onClick={() => handleCardClick(index)}
            >
              <CardContent>
                <Typography sx={{ fontWeight: 'bold', paddingBottom: '4px' }}>
                  <Typography component='span' sx={{ color: 'green' }}>
                    {option.plantSize}
                  </Typography>
                  , {option.title}
                </Typography>
                <Typography variant='caption'>
                  {option.description} {option.billReduction},{' '}
                  {option.firstYearSavings}
                </Typography>
                {selectedOption === index && (
                  <>
                    <Grid container mt={2}>
                      <Grid item xs={9}>
                        <Typography variant='caption' sx={{ color: 'green' }}>
                          Annual energy needs
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <Typography
                          variant='caption'
                          sx={{ fontWeight: 'bold' }}
                        >
                          {option.energyConsumption.toLocaleString()} units
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={9}>
                        <Typography variant='caption' sx={{ color: 'green' }}>
                          {option.plantSize} solar plant production
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <Typography
                          variant='caption'
                          sx={{ fontWeight: 'bold' }}
                        >
                          {option.energyProduction.toLocaleString()} units
                        </Typography>
                      </Grid>
                    </Grid>

                    <ThickSlider
                      value={[20, 80]}
                      min={0}
                      max={100}
                      sx={{ mt: 2, width: '80%', mx: 'auto' }}
                      marks={[
                        {
                          value: 20,
                          label: `₹${option.minPrice}`,
                        },
                        {
                          value: 80,
                          label: `₹${option.maxPrice}`,
                        },
                      ]}
                      isMobile={isMobile}
                    />
                  </>
                )}
                {selectedOption === index && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      mt: 2,
                    }}
                  >
                    <Button variant='contained' onClick={handleConfirm}>
                      Confirm
                    </Button>
                  </Box>
                )}
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
}
