import { useTheme } from '@emotion/react';
import CallIcon from '@mui/icons-material/Call';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Materials } from '../../utils/app.enums';

const OffersNearYou = ({ recommendations }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const openVendorProfile = (vendor) => {
    const vendorNameEncoded = vendor?.establishmentName.replace(/\s+/g, '-');
    const vendorInfo = `${vendorNameEncoded}_${vendor?.id}`;
    navigate(`/vendor-profile/${vendorInfo}`);
  };

  return (
    <Box pt={2}>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'} mb={6}>
        <Stack alignItems={isMobile ? 'flex-start' : 'center'} width={'100%'}>
          {recommendations.map((offer, index) => {
            const plantInfo = offer?.proposalConfig?.config?.plantInfo;
            const pricing = offer?.proposalConfig?.config?.pricing;
            const solarPanelObj =
              offer?.proposalConfig?.config?.materials?.find(
                (material) => material.product === Materials.SOLAR_PANEL,
              );
            const inverterObj = offer?.proposalConfig?.config?.materials?.find(
              (material) => material.product === Materials.INVERTER,
            );
            const materialsCount =
              offer?.proposalConfig?.config?.materials?.length;
            return (
              <Accordion
                key={index}
                sx={{
                  width: '100%',
                  marginTop: 2,
                  borderRadius: '10px',
                  boxShadow: '2px 2px 2px 2px #D3D3D3',
                  maxWidth: '600px',
                  alignContent: 'flex-start',
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <IconButton>
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                  sx={{
                    backgroundColor: theme.palette.secondary.lighter,
                    '& .MuiAccordionSummary-content': {
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: theme.typography.h6.fontSize,
                        fontWeight: 'bold',
                      }}
                    >
                      {plantInfo?.plantSize} kW <br />
                      {plantInfo?.plantType?.toLowerCase()}
                    </Typography>
                    <br />
                    <Typography>
                      <Link onClick={() => openVendorProfile(offer?.vendor)}>
                        by {offer?.vendor?.establishmentName}
                      </Link>
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: 'green',
                      fontWeight: 'bold',
                      fontSize: isMobile
                        ? theme.typography.body1.fontSize
                        : theme.typography.h6.fontSize,
                    }}
                  >
                    ₹ {pricing?.installationCost?.toLocaleString()}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: theme.palette.secondary.lighter }}
                >
                  <Grid container mt={2}>
                    <Grid item xs={6}>
                      <Typography
                        fontWeight='bold'
                        sx={{ fontSize: theme.typography.body1.fontSize }}
                      >
                        Solar panels <br />
                      </Typography>
                      <Typography
                        sx={{ fontSize: theme.typography.body1.fontSize }}
                      >
                        x{solarPanelObj?.quantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Typography
                        fontWeight='bold'
                        sx={{ fontSize: theme.typography.body1.fontSize }}
                      >
                        {solarPanelObj?.brand} - {solarPanelObj?.description}{' '}
                        <br />
                      </Typography>
                      <Typography
                        variant='caption'
                        sx={{ fontSize: theme.typography.body1.fontSize }}
                      >
                        {solarPanelObj?.warrantyInYears} years warranty
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: theme.typography.body1.fontSize,
                        }}
                      >
                        Inverter <br />
                      </Typography>
                      <Typography
                        sx={{ fontSize: theme.typography.body1.fontSize }}
                      >
                        x{inverterObj?.quantity} <br />
                        {materialsCount - 2 > 0 &&
                          `& ${materialsCount - 2} other components`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Typography
                        variant='caption'
                        sx={{
                          fontWeight: 'bold',
                          fontSize: theme.typography.body1.fontSize,
                        }}
                      >
                        {inverterObj?.brand} {inverterObj?.description}
                        <br />
                      </Typography>
                      <Typography
                        sx={{ fontSize: theme.typography.body1.fontSize }}
                      >
                        {inverterObj?.warrantyInYears} years warranty
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ margin: '20px 0px' }} />
                  <Grid container mt={3}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: 'bold',
                          fontSize: theme.typography.body1.fontSize,
                        }}
                      >
                        Payable <br />
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: theme.typography.body1.fontSize,
                        }}
                      >
                        (-) Subsidy
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: 'bold',
                          fontSize: theme.typography.body1.fontSize,
                        }}
                      >
                        ₹ {pricing?.totalCost?.toLocaleString()}
                        <br />
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: theme.typography.body1.fontSize,
                        }}
                      >
                        {' '}
                        (-)
                        {(
                          pricing?.applicableStateSubsidy +
                          pricing?.applicableCentralSubsidy
                        ).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: 'bold',
                          fontSize: theme.typography.body1.fontSize,
                        }}
                      >
                        Net Cost
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: 'bold',
                          fontSize: theme.typography.body1.fontSize,
                        }}
                      >
                        ₹ {pricing?.installationCost?.toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ margin: '20px 0px' }} />
                  <Stack
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Button variant='outlined'>
                      <CallIcon /> Visit
                    </Button>
                    <Button variant='contained'>Quote details</Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};

export default OffersNearYou;
