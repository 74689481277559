import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import EngineeringIcon from '@mui/icons-material/Engineering';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { fadeAnimateVariants, slideAnimateVariants } from '../animate/animate';

const BottomSec = ({ startLink, researchLink }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleSupportButtonClick = () => {
    window.open('https://forms.gle/FYBfYapmfg77pZkz9', '_blank');
  };

  const iconSvgString3 = renderToStaticMarkup(
    <svg xmlns='http://www.w3.org/2000/svg' fill='#1B833312'>
      <OfflineBoltIcon
        fontSize='inherit'
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    </svg>,
  );
  return (
    <Box
      sx={{
        background: `url(${`data:image/svg+xml,${encodeURIComponent(iconSvgString3)}`})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: { md: '65%', xs: '100%' },
        backgroundPosition: { md: 'right top', xs: 'center' },
        minHeight: '100vh',
      }}
    >
      <Stack spacing={'80px'} pt='38px' pb={4}>
        <Stack spacing={'8px'}>
          <Typography
            variant='h2'
            component={motion.h2}
            variants={fadeAnimateVariants}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true }}
            custom={0.5}
            fontSize={{
              md: '48px',
              sm: '40px',
              xs: '34px',
            }}
          >
            <Box
              component='span'
              sx={{
                color: 'primary.main',
              }}
            >
              VaySolar
            </Box>{' '}
            Advantage
          </Typography>
          <Typography
            variant='h5'
            component={motion.h5}
            variants={fadeAnimateVariants}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true }}
            custom={1}
            color='primary.main'
            fontSize={{
              md: '24px',
              sm: '20px',
              xs: '16px',
            }}
          >
            We{' '}
            <Box
              component='span'
              sx={{
                fontWeight: 'bold',
              }}
            >
              selected
            </Box>{' '}
            the best partners so you don't have to!
          </Typography>
        </Stack>
        <Stack spacing='60px'>
          <Stack
            direction='row'
            component={motion.div}
            variants={fadeAnimateVariants}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true }}
            custom={1.5}
            spacing={{
              xs: '20px',
              sm: '30px',
              md: '40px',
            }}
            alignItems={'center'}
          >
            <Box
              width={{
                md: '54px',
                sm: '48px',
                xs: '36px',
              }}
            >
              <OpenInFullIcon
                color='primary'
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
            <Stack spacing='4px'>
              <Typography
                variant='h4'
                fontSize={{
                  md: '29px',
                  sm: '25px',
                  xs: '20px',
                }}
                fontWeight='bold'
              >
                <Box
                  component='span'
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  Single
                </Box>{' '}
                stop shop.
              </Typography>
              <Typography
                variant='h6'
                fontSize={{
                  md: '17px',
                  sm: '15px',
                  xs: '12px',
                }}
              >
                Search. Select. Finance. Install. Maintain.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            component={motion.div}
            variants={fadeAnimateVariants}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true }}
            custom={2.5}
            spacing={{
              xs: '20px',
              sm: '30px',
              md: '40px',
            }}
            alignItems={'center'}
          >
            <Box
              width={{
                md: '54px',
                sm: '48px',
                xs: '36px',
              }}
            >
              <EngineeringIcon
                color='primary'
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
            <Stack spacing='4px'>
              <Typography
                variant='h4'
                fontSize={{
                  md: '29px',
                  sm: '25px',
                  xs: '20px',
                }}
                fontWeight='bold'
              >
                Trusted
                <Box
                  component='span'
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  {' '}
                  local
                </Box>{' '}
                partners.
              </Typography>
              <Typography
                variant='h6'
                fontSize={{
                  md: '17px',
                  sm: '15px',
                  xs: '12px',
                }}
              >
                Over 50+ verified installation partners
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            component={motion.div}
            variants={fadeAnimateVariants}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true }}
            custom={3}
            spacing={{
              xs: '20px',
              sm: '30px',
              md: '40px',
            }}
            alignItems={'center'}
          >
            <Box
              width={{
                md: '54px',
                sm: '48px',
                xs: '36px',
              }}
            >
              <SecurityOutlinedIcon
                color='primary'
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
            <Stack spacing='4px'>
              <Typography
                variant='h4'
                fontSize={{
                  md: '29px',
                  sm: '25px',
                  xs: '20px',
                }}
                fontWeight='bold'
              >
                <Box
                  component='span'
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  3 year risk
                </Box>{' '}
                coverage.
              </Typography>
              <Typography
                variant='h6'
                fontSize={{
                  md: '17px',
                  sm: '15px',
                  xs: '12px',
                }}
              >
                Our partners, our risk gaurantee
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/*Buttons*/}
        <Stack spacing={'15px'}>
          <Stack spacing='15px' direction='row'>
            <Button
              variant='contained'
              size={isMobile ? 'medium' : 'large'}
              component={motion.button}
              variants={slideAnimateVariants}
              initial='initial'
              whileInView='animate'
              viewport={{ once: true }}
              custom={3.5}
              disableElevation
              sx={{
                letterSpacing: '1.3px',
              }}
              endIcon={<ArrowCircleRightIcon />}
              onClick={() => navigate(startLink)}
            >
              Start
            </Button>
            <Button
              size={isMobile ? 'medium' : 'large'}
              variant='text'
              sx={{
                letterSpacing: '0.7px',
              }}
              component={motion.button}
              onClick={() => {
                if (researchLink) {
                  navigate(researchLink);
                } else {
                  window.location.href =
                    'https://www.vaysolar.com/docs/?utm_source=landing-page&utm_medium=landing-page&utm_campaign=landing-page';
                }
              }}
              variants={slideAnimateVariants}
              initial='initial'
              whileInView='animate'
              viewport={{ once: true }}
              custom={4}
              startIcon={<EnergySavingsLeafOutlinedIcon />}
            >
              Research
            </Button>
          </Stack>
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.body2.fontSize,
                md: theme.typography.body2.fontSize,
                color: theme.palette.primary.main,
              },
            }}
          >
            <Link onClick={handleSupportButtonClick}>
              Customized request? Talk to an expert
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BottomSec;
