import {
  AccountCircle,
  Assignment,
  Engineering,
  ExitToApp,
  Home,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import QuizIcon from '@mui/icons-material/Quiz';
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Collapse,
  Container,
  Fab,
  Link,
  Stack,
  SvgIcon,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InstallationStatus from '../components/dashboard/InstallationStatus';
import LanguageMenu from '../components/LanguageMenu';
import Logo from '../components/Logo';
import { SideNavItem } from '../components/nav/SideNavItem';
import SearchOnAnimation from '../components/SearchOnAnimation';
import { getUserDetails } from '../services/UserDetailsService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { useSearchAnimate } from '../store/SearchAnimateStore';
import { isLoggedIn } from '../utils/utils';
import SEO from './SEO';

const drawerWidth = 240;
const navItems = [
  {
    displayName: 'Dashboard',
    pathname: '/dashboard',
    tabIndex: 0,
    icon: (
      <SvgIcon fontSize='small'>
        <Home />
      </SvgIcon>
    ),
  },
  {
    displayName: 'Help',
    external: true,
    pathname: 'https://forms.gle/FYBfYapmfg77pZkz9',
    tabIndex: 1,
    icon: (
      <SvgIcon fontSize='small'>
        <QuizIcon />
      </SvgIcon>
    ),
  },
  {
    displayName: 'Account',
    pathname: '/profile',
    tabIndex: 2,
    icon: (
      <SvgIcon fontSize='small'>
        <AccountCircle />
      </SvgIcon>
    ),
  },
];

export const DashboardLayout = ({
  children,
  announcementBar,
  installationTimeline,
  propertyDetails,
}) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [navValue, setNavValue] = useState(null);
  const { isSearchOver, setIsSearchOver } = useSearchAnimate();
  const { useTitle, useImage, useDescription } = usePageTitleStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const onMount = async () => {
      const data = getUserDetails();
      setUserDetails(data);
    };
    onMount();
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsSearchOver(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    history({ pathname: '/' });
  };

  const handleSupportButtonClick = () => {
    window.open('https://forms.gle/FYBfYapmfg77pZkz9', '_blank');
  };

  const drawer = (
    <>
      <Stack
        component='ul'
        spacing={1}
        sx={{
          listStyle: 'none',
          py: 5,
          px: 3,
          m: 0,
          mt: 0,
          height: '100%',
        }}
        onClick={handleDrawerToggle}
      >
        <Typography variant='h6'>Hi, {userDetails?.name ?? 'User'}</Typography>
        {navItems.map((item) => {
          const active = item.path ? location.pathname === item.path : false;
          return (
            <SideNavItem
              active={active}
              disabled={item.disabled}
              external={item.external}
              icon={item.icon}
              key={item.displayName}
              path={item.pathname}
              title={item.displayName}
            />
          );
        })}
        <Box
          sx={{
            display: { xs: 'flex' },
            cursor: 'pointer',
            height: '30px',
            width: '100px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            '&:hover': { bgcolor: '#f0faf3' },
          }}
          color={'neutral.main'}
        >
          {isLoggedIn() && (
            <Stack
              direction={'row'}
              ml={2}
              alignItems={'center'}
              spacing={2}
              onClick={() => handleLogout()}
            >
              <SvgIcon sx={{ height: '18px', width: '18px' }}>
                <ExitToApp />
              </SvgIcon>

              <Typography
                variant='subtitle1'
                fontSize={'15px'}
                fontWeight={540}
              >
                Logout
              </Typography>
            </Stack>
          )}
        </Box>
        <Box marginTop={'auto !important'} sx={{ alignSelf: 'center' }}>
          <Logo height={52} />
        </Box>
        <Stack alignItems={'center'}>
          <Link href='https://www.vaysolar.com/docs/privacy/' target='_blank'>
            <Typography fontSize={theme.typography.caption.fontSize}>
              Privacy Policy
            </Typography>
          </Link>
          <Typography
            fontSize={theme.typography.caption.fontSize}
            color='text.secondary'
          >
            © Copyright | VaySolar
          </Typography>
        </Stack>
      </Stack>
    </>
  );

  const ProfileButton = () => {
    return (
      <Button
        endIcon={<AccountCircle />}
        onClick={() => history({ pathname: '/profile' })}
      >
        Account
      </Button>
    );
  };

  const ProjectsButton = () => {
    return (
      <Button
        endIcon={<Assignment />}
        onClick={() => history({ pathname: '/projects' })}
      >
        Shortlists
      </Button>
    );
  };

  return (
    <>
      <SEO
        title={useTitle}
        image={useImage}
        description={useDescription}
        name='VaySolar'
        type='article'
      />
      <nav>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <SearchOnAnimation
        isSearchOver={isSearchOver}
        propertyDetails={propertyDetails}
      />
      <Collapse
        in={isSearchOver}
        collapsedSize={0}
        unmountOnExit
        mountOnEnter
        timeout={1800}
      >
        {announcementBar ? (
          <InstallationStatus
            propertyDetails={propertyDetails}
            installationTimeline={installationTimeline}
          />
        ) : (
          ''
        )}
      </Collapse>
      <Collapse in={isSearchOver} collapsedSize={0} unmountOnExit mountOnEnter>
        <Box sx={{ bgcolor: 'white', maxWidth: '1024px', mx: 'auto' }}>
          <Container
            sx={{
              paddingInline: '0px !important',
              boxShadow: { xs: '0px 2px 2px rgba(0, 0, 0, 0.25)', sm: 'none' },
              marginTop: { xs: '8px', sm: '0px' },
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'row'}
              height={{ xs: '48px', sm: '64px' }}
            >
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                sx={{
                  display: { sm: 'none' },
                  paddingLeft: '24px',
                  paddingBottom: '20px',
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  display: { xs: 'flex' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: '80%', sm: 'auto' },
                }}
                onClick={() => history({ pathname: '/dashboard' })}
              >
                <Logo />
              </Box>
              <Stack
                flex={1}
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                pr={'12px'}
                spacing={'8px'}
              >
                {!isMobile && (
                  <>
                    <ProjectsButton />
                    <ProfileButton />
                  </>
                )}
                <LanguageMenu />
                {isLoggedIn() ? (
                  <Button
                    endIcon={<ExitToApp />}
                    onClick={() => handleLogout()}
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                    }}
                  >
                    Logout
                  </Button>
                ) : (
                  ''
                )}
              </Stack>
            </Box>
          </Container>
        </Box>
      </Collapse>
      <Collapse in={isSearchOver} collapsedSize={0} unmountOnExit mountOnEnter>
        <Box sx={{ maxWidth: '1048px', mx: 'auto' }}>
          <Box pb={'48px'}>{children}</Box>
          <Fab
            sx={{
              position: 'fixed',
              bottom: { md: 24, xs: 72 },
              right: { md: 96, xs: 16 },
              zIndex: theme.zIndex.tooltip,
            }}
            color='primary'
            onClick={handleSupportButtonClick}
            variant='extended'
          >
            <Engineering sx={{ mr: 1 }} />
            Expert Call
          </Fab>
        </Box>
      </Collapse>
      <Box
        sx={{
          display: { xs: 'block', sm: 'none' },
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: theme.zIndex.appBar,
        }}
      >
        <BottomNavigation
          showLabels
          value={navValue}
          onChange={(event, newValue) => {
            setNavValue(newValue);
            switch (newValue) {
              case 0:
                history('/dashboard');
                break;
              case 1:
                history('/projects');
                break;
              case 2:
                history('/profile');
                break;
              default:
                break;
            }
          }}
        >
          <BottomNavigationAction label='My offers' icon={<Home />} />
          <BottomNavigationAction label='Shortlists' icon={<Assignment />} />
          <BottomNavigationAction label='Account' icon={<AccountCircle />} />
        </BottomNavigation>
      </Box>
    </>
  );
};
