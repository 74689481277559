import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

// TODO: Have a env var to separate dev and prod
ReactGA.initialize('G-LKD9JYPHBW', {
  gaOptions: {
    debug_mode: process.env.GA_DEBUG_MODE,
  },
  gtagOptions: {
    debug_mode: process.env.GA_DEBUG_MODE,
  },
});

TagManager.initialize({
  gtmId: 'GTM-TCJBNN3K',
  debug_mode: process.env.GA_DEBUG_MODE,
});

const clientId =
  '196119675936-rok53b35n0np7bu503c0lte1rpp6gcn1.apps.googleusercontent.com';

const theme = createTheme({
  palette: {
    primary: {
      main: '#216b38',
      light: '#0abd61',
    },
    secondary: {
      main: '#fcfcfc',
      light: '#1B8333',
      lighter: '#A8D49957',
    },
    text: {
      primary: '#000000',
      secondary: '#0000006B',
      tertiary: '#0000002B',
    },
    background: {
      paper: '#fffeff',
    },
    accent: {
      primary: '#216b38',
    },
    border: {
      card: '#d5e0d5',
    },
    neutral: {
      main: '#00000099',
    },
    warning: {
      main: '#FFC107',
      secondary: '#FB8C00',
    },
    error: {
      main: '#CC0000',
    },
    brown: '#9E6D6D',
    golden: '#ADA71F',
    black: '#000000',
    gray: '#0000008F',
    grayLight: '#D9D9D9',
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {},
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          borderRadius: '8px',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '36px',
          '.MuiTabs-indicator': {
            height: '2.5px',
            borderRadius: '9px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          minHeight: '36px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          ':hover': {
            boxShadow: '0px 0px 1px 1px #216b38',
          },
          // boxShadow: "0px 2px 10px 10px #000",
        },
      },
    },
  },
  typography: {
    fontFamily: '"Plus Jakarta Sans", sans-serif',
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57,
    },
    button: {
      fontWeight: 600,
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.66,
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.57,
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: 1.57,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase',
    },
    h1: {
      fontSize: '3.5rem',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '3rem',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '2.25rem',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '2rem',
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1.5rem',
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '1.160rem',
      lineHeight: 1.2,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={clientId}>
          <App />
        </GoogleOAuthProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
