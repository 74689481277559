import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import QuoteDetails from '../components/dashboard/QuoteDetails';
import { DashboardLayout } from '../layout/DashboardLayout';
import { getProposalsByUserId } from '../services/CustomerService';

export default function ProjectsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    const onMount = async () => {
      try {
        const proposalsResponse = await getProposalsByUserId();
        setProposals(proposalsResponse);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    onMount();
  }, []);

  return (
    <DashboardLayout>
      <Box
        sx={{
          maxWidth: { sm: 800, xs: 400 },
          px: 2,
          pt: 2,
        }}
      >
        <Typography sx={{ fontSize: '24px', mb: '4px' }}>
          Your shortlisted quotes
        </Typography>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <QuoteDetails proposals={proposals} />
        )}
      </Box>
    </DashboardLayout>
  );
}
