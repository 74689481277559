import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { updateInstallationTimeline } from '../../services/CustomerService';
import { InstallationTimeline } from '../../utils/app.enums';

export default function SolarSearch({ propertyDetails }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const options = [
    {
      id: 1,
      name: 'In 1 months',
      value: InstallationTimeline.ONE_MONTH,
      details: 'as soon as possible',
    },
    {
      id: 2,
      name: '1-3 months',
      value: InstallationTimeline.ONE_THREE_MONTHS,
      details: 'in no hurry to install',
    },
    {
      id: 3,
      name: 'Just researching',
      value: InstallationTimeline.THREE_PLUS_MONTHS,
      details: 'help me with research',
    },
  ];

  const handleCardClick = (index) => {
    setSelectedOption(selectedOption === index ? null : index);
  };

  const handleConfirm = async () => {
    if (selectedOption !== null) {
      const selectedOptionDetails = options[selectedOption];
      await updateInstallationTimeline(
        propertyDetails,
        selectedOptionDetails.value,
      );
      window.location.reload();
    }
  };

  return (
    <Stack
      alignItems={isMobile ? 'flex-start' : 'center'}
      padding={isMobile ? '0px 16px' : 'unset'}
    >
      <Typography
        sx={{
          fontSize: {
            xs: theme.typography.h5.fontSize,
            md: theme.typography.h4.fontSize,
          },
          fontWeight: 'bold',
          marginTop: '10px',
        }}
      >
        Solar search is{' '}
        <span style={{ color: theme.palette.warning.secondary }}>off</span>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: theme.typography.h6.fontSize,
            md: theme.typography.h5.fontSize,
          },
          fontWeight: 'bold',
          marginTop: '10px',
        }}
      >
        Looking for solar?
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: theme.typography.h6.fontSize,
            md: theme.typography.h5.fontSize,
          },
          fontWeight: 'bold',
        }}
      >
        Update tentative timeline
      </Typography>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'} width={'100%'}>
        {options.map((option, index) => (
          <Card
            key={option.id}
            sx={{
              backgroundColor:
                selectedOption === index
                  ? theme.palette.secondary.lighter
                  : theme.palette.background.paper,
              cursor: 'pointer',
              width: '100%',
              marginTop: 2,
              borderRadius: '10px',
              boxShadow: '2px 2px 2px 2px #D3D3D3',
              maxWidth: '500px',
            }}
            onClick={() => handleCardClick(index)}
          >
            <CardContent>
              <Typography sx={{ fontWeight: 'bold', paddingBottom: '4px' }}>
                <Typography
                  component='span'
                  sx={{ color: 'green' }}
                  fontWeight='bold'
                >
                  {option.name}
                </Typography>
              </Typography>
              <Typography variant='caption'>{option.details}</Typography>
              {selectedOption === index && (
                <>
                  <Grid container mt={2} justifyContent={'flex-end'}>
                    <Button variant='contained' onClick={handleConfirm}>
                      Confirm
                    </Button>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
}
