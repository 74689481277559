import CloseIcon from '@mui/icons-material/Close';
import { Grow, IconButton, Snackbar } from '@mui/material';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import CongratsPage from './pages/CongratsPage';
import CustomerJourney from './pages/CustomerJourney';
import CustomerJourney2 from './pages/CustomerJourney2';
import DashboardPage from './pages/Dashboard';
import ForbiddenErrorPage from './pages/ForbiddenErrorPage';
import InternalErrorPage from './pages/InternalErrorPage';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import ProfilePage from './pages/ProfilePage';
import ProjectsPage from './pages/ProjectsPage';
import VendorProfileList from './pages/VendorProfileList';
import VendorProfilePage from './pages/VendorProfilePage';
import RouteGuard from './services/RouteGuard';
import { useSearchAnimate } from './store/SearchAnimateStore';

function GrowTransition(props) {
  return <Grow {...props} />;
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const helmetContext = {};
  const { isAlert, setIsAlert } = useSearchAnimate();

  useEffect(() => {
    const languageCode = location.pathname.split('/')[1];
    const validLanguages = ['en', 'hi', 'ta'];

    if (!validLanguages.includes(languageCode)) {
      navigate('/en' + location.pathname + location.search, { replace: true });
    }
  }, [location, navigate]);

  return (
    <HelmetProvider context={helmetContext}>
      <Routes>
        <Route path='/' element={<Navigate to='/en' />} />
        <Route path='/:lang' element={<LandingPage />} />
        <Route path='/:lang/login' element={<LoginPage />} />
        <Route path='/:lang/start' element={<CustomerJourney />} />
        <Route path='/:lang/site-details' element={<CustomerJourney2 />} />
        <Route
          path='/:lang/vendors'
          element={
            <RouteGuard>
              <VendorProfileList />
            </RouteGuard>
          }
        />
        <Route
          path='/:lang/vendor-profile/:vendorInfo'
          element={<VendorProfilePage />}
        />
        <Route
          path='/:lang/dashboard'
          element={
            <RouteGuard>
              <DashboardPage />
            </RouteGuard>
          }
        />
        <Route path='/:lang/congratulations' element={<CongratsPage />} />
        <Route path='/:lang/projects' element={<ProjectsPage />} />
        <Route path='/:lang/profile' element={<ProfilePage />} />
        <Route path='/:lang/error' exact Component={InternalErrorPage} />
        <Route path='/:lang/forbidden' exact Component={ForbiddenErrorPage} />
        <Route path='*' Component={NotFoundPage} />
      </Routes>
      <Snackbar
        open={isAlert.show}
        TransitionComponent={GrowTransition}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          top: '50px !important',
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'white',
            color: '#CC0000',
          },
        }}
        autoHideDuration={1500}
        onClose={() => setIsAlert({ show: false, msg: isAlert.msg })}
        message={isAlert.msg}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setIsAlert({ show: false, msg: isAlert.msg })}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      />
    </HelmetProvider>
  );
}

export default App;
