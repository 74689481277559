import {
  Box,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { VerificationStatus } from '../../utils/app.enums';
// import InstallersStep from './InstallersStep';
import OffersNearYou from './OffersNearYou';
import VendorAccordion from './VendorAccordion';
import VerifyingStep from './VerifyingStep';

export default function DashboardStepper({
  propertyDetails,
  vendorData,
  recommendations,
}) {
  const steps = [
    {
      label:
        propertyDetails?.verificationStatus === VerificationStatus.PENDING
          ? 'Verifying details'
          : propertyDetails?.verificationStatus === VerificationStatus.REJECTED
            ? 'Verification failed'
            : 'Verified',
    },
    {
      label: 'Offers near you',
    },
    {
      label: 'Installers near you',
    },
  ];
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (vendorData?.length > 0) {
      setActiveStep(1);
    }
    if (vendorData.length > 0) {
      setActiveStep(2);
    }
  }, [vendorData]);

  return (
    <Box>
      <Stack spacing={2}>
        <Box
          sx={{
            maxWidth: { sm: 800, xs: 400 },
          }}
        >
          <Stepper activeStep={activeStep} orientation='vertical'>
            {steps.map((step, index) => (
              <Step key={step.label} expanded>
                <StepLabel
                  sx={{
                    alignItems: 'start',
                    '& .MuiStepIcon-text': {
                      fill: 'white',
                      fontSize: '0.8rem',
                    },
                    gap: { sm: '4px', xs: 0 },
                  }}
                >
                  <Stack ml={1}>
                    <Typography
                      variant='h5'
                      component={'p'}
                      fontSize={{
                        sm: '18px',
                        xs: '16px',
                      }}
                    >
                      {step.label}
                    </Typography>
                    {index === 1 && (
                      <Typography
                        fontWeight='500'
                        variant='body2'
                        sx={{
                          color: 'text.secondary',
                        }}
                      >
                        {recommendations.length === 0
                          ? 'No offers available for you'
                          : `${recommendations.length} offers available for you`}
                      </Typography>
                    )}
                    {index === 2 && (
                      <Typography
                        fontWeight='500'
                        variant='body2'
                        sx={{
                          color: 'text.secondary',
                        }}
                      >
                        {vendorData.length === 0
                          ? 'No installers available in your region'
                          : `${vendorData.length} installers in your region`}
                      </Typography>
                    )}
                  </Stack>
                </StepLabel>
                <StepContent
                  sx={{
                    paddingLeft: 1,
                    paddingRight: 0,
                  }}
                >
                  {index === 0 ? (
                    <VerifyingStep
                      propertyId={propertyDetails?.id}
                      electricityBillUrl={propertyDetails?.electricityBillUrl}
                      verificationStatus={propertyDetails?.verificationStatus}
                      sanctionedLoad={propertyDetails?.sanctionedLoad}
                    />
                  ) : index === 1 ? (
                    <OffersNearYou recommendations={recommendations} />
                  ) : index === 2 ? (
                    // <InstallersStep numberOfInstallers={vendorData?.length} />
                    <VendorAccordion
                      vendorData={vendorData}
                      customerId={propertyDetails?.userId}
                      propertyId={propertyDetails?.id}
                      siteVisitData={propertyDetails?.siteVisitData}
                    />
                  ) : null}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Stack>
    </Box>
  );
}
